import { useTranslation } from "react-i18next";
import React, { useState } from "react";  
import { Icon } from "../Icon";
import DatePicker from "../DatePicker/DatePicker";
import { useSelector } from "react-redux";
import getDate from "../../helpers/getDate";

const Filter=(props)=>{
    const { t } = useTranslation();
    const [form,setForm]=useState({})
    const fullList = useSelector((state) => state.fullList);

    const toggleSidebar = () => {
        props.setIsOpen(!props.isOpen);
    };
  
    const closeSidebar = (e) => {
      if (e.target.classList.contains("overlay")) {
        props.setIsOpen(false);
      }
    };


    const changeHandler = (event) => {
        setForm({ ...form, [event.target.id]: event.target.value });
        props.onChange(event);
    };

    const acceptClick=()=>{
        props.accept();
        toggleSidebar();
    }



    return(<>
        <div className="overlay" onClick={closeSidebar}>
          <div className="sidebar">
            <h3 className="prFlexRow">
                <div className="sidebarFilter">
                    <Icon type='filter'></Icon> 
                    <div>{t('filter_header')}</div>
                </div> 
                <div className="prFlexRow sidebarFilterButtons"> 
                    <div onClick={props.resetFilter}><Icon type='clear'></Icon></div> 
                    <div onClick={toggleSidebar}><Icon type='close'></Icon></div>
                </div>
            </h3>
            <div className="sidebarBody">
            <div className="prInput">
                <label htmlFor="all" className={"prInputLabel"}>{t("search_name_nick_enail")}</label>
                <input onChange={changeHandler} id="all" type="text" className={"prInputInput"} placeholder={t("enter_value")} />
            </div>
            <div className="prInput">
                <label htmlFor="role" className={"prInputLabel"}>{t("role_system")}</label>
                        <select
                        className={ "prInputInput"}
                            id="role"
                            value={props.filter.role || ""}
                            onChange={changeHandler}
                        >
                        <option value="">{t('empty_select')}</option>
                        {fullList.UserRoles.length > 0 &&
                            fullList.UserRoles.map(el => (
                            <option key={el.id} value={el.id}>{el.title}</option>
                        ))
                        }
                    </select>
            </div>
            <div className="prInputPeriod">
                <label htmlFor="birthday_period" className={"prInputLabel"}>{t("birthday_period")}</label>
                <div className="prFlexRow">
                    <div><DatePicker onChange={changeHandler} id="dataBirthdayFrom" value={(props.filter.dataBirthdayFrom && getDate(props.filter.dataBirthdayFrom,'dd/mm/yyyy','.')) || ''} icon={true}></DatePicker></div>
                    <div className="prInputPeriodDevider">-</div>
                    <div><DatePicker onChange={changeHandler} id="dataBirthdayTo" value={(props.filter.dataBirthdayTo && getDate(props.filter.dataBirthdayTo,'dd/mm/yyyy','.')) || ''}icon={true}></DatePicker></div>
                </div>
            </div>

            <div  className="prInput">
                <label htmlFor="sex" className={"prInputLabel"}>{t("gender")}</label>
                <select
                    className={"prInputInput"}
                    id="sex"
                    value={props.filter.sex || ""}
                    onChange={changeHandler}
                >
                    <option value="">{t('empty_select')}</option>
                    {fullList.Sex.length > 0 &&
                        fullList.Sex.map(el => (
                            <option key={el.id} value={el.id}>{el.title}</option>
                        ))
                    }
                </select>
            </div>
            <div  className="prInput">
                <label htmlFor="game" className={"prInputLabel"}>{t("game")}</label>
                <select disabled className={"prInputInput"} name="" id="">
                    <option value="">{t('empty_select')}</option>
                    <option value="">CS</option>
                    <option value="">Dota</option>
                    <option value="">WoT</option>
                </select>
            </div>
            <div className="prInputPeriod">
                <label htmlFor="reputationFrom" className={"prInputLabel"}>{t("reputation")}</label>
                <div className="prFlexRow">
                    <select className={"prInputInput"}  id="reputationFrom"
                                    value={props.filter?.reputationFrom || ""}
                                    onChange={changeHandler}
                                    >
                        <option value=""></option>
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                    </select>
                    <div className="prInputPeriodDevider">-</div>
                    <select className={"prInputInput"}  id="reputationTo"
                                    value={props.filter?.reputationTo || ""}
                                    onChange={changeHandler}
                                    >
                        <option value=""></option>
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                    </select>
                </div>
            </div>
            <div  className="prInput">
                <label htmlFor="status" className={"prInputLabel"}>{t("status")}</label>
                <select disabled className={"prInputInput"} name="" id="">
                    <option value="">{t('empty_select')}</option>
                    <option value="">Верифіковано</option>
                    <option value="">Очікує</option>
                    <option value="">Не верифіковано</option>
                </select>
            </div>
                <div className="userEditButtons">
                    <button className="prButton prButtonSecond" onClick={toggleSidebar}>Відмінити</button>
                    <button className="prButton prButtonMain" onClick={acceptClick}>Застосувати</button>
                </div>
                <button className="w100 prButton prButtonSecond"  onClick={props.resetFilter}><Icon type='clear'></Icon> Очистити фільтр</button>
            </div>
          </div>
        </div>    
    </>)
}

export default Filter;