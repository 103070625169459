import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SEND_CODE,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_FAIL,
  RESTORE_SUCCESS,
  RESTORE_FAIL,
  ERROR_FREE,ERROR
} from "./types";

import AuthService from "../services/auth.service";


export const register = (form) => (dispatch) => {
  return AuthService.register(form).then(
    (response) => {
      if(response.statusText!="OK" || response.data.errors){
        dispatch({
          type: REGISTER_FAIL,
          payload: response.data,
        });
      return response.data;
      }else{
        dispatch({
          type: REGISTER_SUCCESS,
          payload: response.data,
        });
        return  response.data;
      }
      
    },
    (error) => {
      dispatch({
        type: REGISTER_FAIL,
      });
      dispatch({
        type: ERROR,
        message:error.message
    });
    console.log(error);
      return Promise.reject();
    }
  );
};


export const login = (body) => (dispatch) => {
   
  return AuthService.login(body).then(
    (data) => {
      if( !data.error ){
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { user: data },
        });
      }
      else{
        dispatch({
          type: LOGIN_FAIL,
        });
      }
      return data;
    },
    (error) => {
      dispatch({
        type: LOGIN_FAIL,
      });
      dispatch({
        type: ERROR,
        message:error.message
    });
      console.log(error);
      return Promise.reject();
    }
  );
};

export const restore = (data)=>(dispatch)=>{
     
  return AuthService.restore(data).then((response)=>{
    dispatch({
      type: RESTORE_SUCCESS,
    });
    return response.data;
  },
  (error) => {
    dispatch({
      type: RESTORE_FAIL,
      payload: error.response
    });

    dispatch({
      type: ERROR,
      message:error.message
  });
  console.log(error);
    return Promise.reject();
  })

}

export const logout = () => (dispatch) => {
  AuthService.logout();
  dispatch({
    type: LOGOUT,
  });
};
export const registerCode = (body) => (dispatch) => {
  return AuthService.registerCode(body).then(
    (response) => {
      if(response.statusText!="OK" || response.data.errors){
        dispatch({
          type: SEND_CODE,
        });
      return response.data;
      }else{
        dispatch({
          type:  SEND_CODE,
        });
        return  response.data;
      }
    },
    (error) => {
      dispatch({
        type:  SEND_CODE,
      });
      dispatch({
        type: ERROR,
        message:error.message
    });
    console.log(error);
      return Promise.reject();
    }
  );
};

export const restoreCode = (body) => (dispatch) => {
  return AuthService.restoreCode(body).then(
    (response) => {
      if(response.statusText!="OK" || response.data.errors){
        dispatch({
          type: SEND_CODE,
        });
      return response.data;
      }else{
        dispatch({
          type:  SEND_CODE,
        });    
        return Promise.reject();
      }
    },
    (error) => {
      dispatch({
        type:  SEND_CODE,
      });
      dispatch({
        type: ERROR,
        message:error.message
    });
    console.log(error);
      return Promise.reject();
    }
  );
};



export const refreshToken = (dispatch, refreshToken) => {
  return AuthService.refresh(refreshToken)
    .then(response => {
        console.log('Response:', response); // Log the full response
        const { accessToken, refreshToken: newRefreshToken, user } = response.data || {}; // Handle possible undefined response.data
        if (!accessToken || !newRefreshToken) {
            throw new Error('Invalid response format');
        }
        localStorage.setItem('userData', JSON.stringify({
            accessToken,
            refreshToken: newRefreshToken,
            user
        }));
        dispatch({
            type: REFRESH_TOKEN_SUCCESS,
            payload:response.data
        });

        return Promise.resolve();
    })
    .catch(error => {
        console.error('Refresh token error:', error); // Log the error for debugging
        dispatch({
            type: REFRESH_TOKEN_FAIL,
            payload: error.message
        });
        return Promise.reject(error);
    });
};

export const errorFree = () => (dispatch) => {
  dispatch({
    type: ERROR_FREE
  });
  return Promise.resolve(true);
};