export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const SEND_CODE = "SEND_CODE";
export const DONE_REFRESHING_TOKEN = "DONE_REFRESHING_TOKEN";
export const REFRESHING_TOKEN = "REFRESHING_TOKEN";
export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";
export const REFRESH_TOKEN_FAIL = "REFRESH_TOKEN_FAIL";

export const CREATEROOM_SUCCESS = "CREATEROOM_SUCCESS";
export const CREATEROOM_FAIL = "CREATEROOM_FAIL";
export const GETROOMLIST_SUCCESS = "GETROOMLIST_SUCCESS";
export const GETROOMLIST_FAIL = "GETROOMLIST_FAIL";
export const GETROOMDETAIL_SUCCESS = "GETROOMDETAIL_SUCCESS";
export const GETROOMDETAIL_FAIL = "GETROOMDETAIL_FAIL";
export const SETROOMLIST_SUCCESS = "SETROOMLIST_SUCCESS";
export const DELETEROOM_FAIL = "DELETEROOM_FAIL";
export const DELETEROOM_SUCCESS = "DELETEROOM_SUCCESS";
export const JOINROOM_SUCCESS = "JOINROOM_SUCCESS";
export const JOINROOM_FAIL = "JOINROOM_FAIL";
export const LEAVEROOM_FAIL = "LEAVEROOM_FAIL";
export const LEAVEROOM_SUCCESS = "LEAVEROOM_SUCCESS";

export const GETUSERDETAIL_FAIL = "GETUSERDETAIL_FAIL";
export const GETUSERDETAIL_SUCCESS = "GETUSERDETAIL_SUCCESS";
export const GETCURRENTUSERDETAIL_SUCCESS = "GETCURRENTUSERDETAIL_SUCCESS";
export const GETCURRENTUSERDETAIL_FAIL = "GETCURRENTUSERDETAIL_FAIL";

export const RESTORE_SUCCESS = "RESTORE_SUCCESS";
export const RESTORE_FAIL = "RESTORE_FAIL";

export const GETUSERSLIST_FAIL = "GETUSERSLIST_FAIL";
export const GETUSERSLIST_SUCCESS = "GETUSERSLIST_SUCCESS";
export const CREATEUSER_FAIL = "CREATEUSER_FAIL";
export const CREATEUSER_SUCCESS = "CREATEUSER_SUCCESS";
export const EDITUSER_SUCCESS = "EDITUSER_SUCCESS";
export const EDITUSER_FAIL = "EDITUSER_FAIL";

export const DICTIONARIESFULLLIST_SUCCESS = "DICTIONARIESFULLLIST_SUCCESS";
export const DICTIONARIESFULLLIST_FAIL = "DICTIONARIESFULLLIST_FAIL";
export const LISTID_SUCCESS = "LISTID_SUCCESS";
export const DICTIONARIESLIST_SUCCESS = "DICTIONARIESLIST_SUCCESS";
export const DICTIONARIESLIST_FAIL = "DICTIONARIESLIST_FAIL";
export const ADDITIONALDICTIONARIESLIST_SUCCESS = "ADDITIONALDICTIONARIESLIST_SUCCESS";
export const ADDITIONALDICTIONARIESLIST_FAIL = "ADDITIONALDICTIONARIESLIST_FAIL";
export const DICTIONARIESDELETE_SUCCESS = "DICTIONARIESDELETE_SUCCESS";
export const DICTIONARIESEDIT_SUCCESS = "DICTIONARIESEDIT_SUCCESS";
export const DICTIONARIESEDIT_FAIL = "DICTIONARIESEDIT_FAIL";
export const DICTIONARIESADD_SUCCESS = "DICTIONARIESADD_SUCCESS";
export const DICTIONARIESADD_FAIL = "DICTIONARIESADD_FAIL";
export const DICTIONARIESITEM_SUCCESS = "DICTIONARIESITEM_SUCCESS";
export const DICTIONARIESITEM_FAIL = "DICTIONARIESITEM_FAIL";
export const DICTIONARIESDELETE_FAIL = "DICTIONARIESDELETE_FAIL";


export const ERROR = "ERROR";
export const ERROR_FREE = "ERROR_FREE";


