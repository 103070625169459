import {
  GETUSERDETAIL_FAIL,
  GETUSERDETAIL_SUCCESS,
  GETCURRENTUSERDETAIL_SUCCESS,
  GETCURRENTUSERDETAIL_FAIL,
  GETUSERSLIST_FAIL,
  GETUSERSLIST_SUCCESS,
  CREATEUSER_FAIL,
  CREATEUSER_SUCCESS,
  EDITUSER_SUCCESS,
  EDITUSER_FAIL,
  ERROR
  } from "./types";
  
  import UserService from "../services/user.service";
  
    export const getUserDetail =(form)=>(dispatch)=>{
        return UserService.getUserDetail(form).then( (response) => {
            if(response.statusText!="OK" || response.data.errors){
              dispatch({
                type: GETUSERDETAIL_FAIL,
                payload: response.data,
              });
            return response.data;
            }else{
              dispatch({
                type: GETUSERDETAIL_SUCCESS,
                payload: response.data,
              });
              return  response.data;
            }
          },
          (error) => {
            dispatch({
              type: GETUSERDETAIL_FAIL,
            });
            dispatch({
              type: ERROR,
              message:error.message
          });
          console.log(error);
            return Promise.reject();
          }
        );
    }
    export const getCurrentUserDetail =(form)=>(dispatch)=>{
      return UserService.getUserDetail(form).then( (response) => {
          if(response.statusText!="OK" || response.data.errors){
            dispatch({
              type: GETCURRENTUSERDETAIL_FAIL,
              payload: response.data,
            });
          return response.data;
          }else{
            dispatch({
              type: GETCURRENTUSERDETAIL_SUCCESS,
              payload: response.data,
            });
            return  response.data;
          }
        },
        (error) => {
          dispatch({
            type: GETCURRENTUSERDETAIL_FAIL,
          });
          dispatch({
            type: ERROR,
            message:error.message
        });
        console.log(error);
          return Promise.reject();
        }
      );
  }

  export const getUsersList=(form)=>(dispatch)=>{
    return UserService.getUsersList(form).then( (response) => {
        if(response.statusText!="OK" || response.data.errors){
          dispatch({
            type: GETUSERSLIST_FAIL,
            payload: response.data,
          });
        return response.data;
        }else{
          dispatch({
            type: GETUSERSLIST_SUCCESS,
            payload: response.data,
          });
          return  response.data;
        }
      },
      (error) => {
        dispatch({
          type: GETUSERSLIST_FAIL,
        });
        dispatch({
          type: ERROR,
          message:error.message
      });
      console.log(error);
        return Promise.reject();
      }
    );
}

export const createUser=(form)=>(dispatch)=>{
  return UserService.createUser(form).then( (response) => {
        dispatch({
          type: CREATEUSER_SUCCESS,
          payload: response.user,
        });
        return  response.user;
    },
    (error) => {
      dispatch({
        type: CREATEUSER_FAIL,
      });
      dispatch({
        type: ERROR,
        message:error.message
    });
    console.log(error);
      return Promise.reject();
    }
  );
}
export const editUser=(form)=>(dispatch)=>{
  return UserService.editUser(form).then( (response) => {
      if(response.statusText!="OK" || response.data.errors){
        dispatch({
          type: EDITUSER_FAIL,
          payload: response.data,
        });
      return response.data;
      }else{
        dispatch({
          type: EDITUSER_SUCCESS,
          payload: response.data,
        });
        return  response.data;
      }
    },
    (error) => {
      dispatch({
        type: EDITUSER_FAIL,
      });
      dispatch({
        type: ERROR,
        message:error.message
    });
      console.log(error);
      return Promise.reject();
    }
  );
}



  


  
  
  