import { combineReducers } from "redux";
import auth from "./auth";
import room from "./room";
import user from "./user";
import currentUser from "./currentUser";
import currentRoom from "./currentRoom";
import fullList from './fullList';
import list from './list';
import error from './error';

export default combineReducers({
  auth,
  currentUser,
  room,
  user,
  currentRoom,
  fullList,
  list,
  error
  });
