import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../redux/actions/auth';
import { Icon } from '../Icon';

const HeaderAuth = (props) => {
    const { t } = useTranslation();
    const user = useSelector((state) => state.currentUser.user);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [openRight, setOpenRight] = useState(false);

    const menuRef = useRef(null); // Ref for the left side menu
    const menuRightRef = useRef(null); // Ref for the right side menu

    let reputation = 10;

    const logOutClick = () => {
        dispatch(logout());
    };

    const setTopicClick = (topic) => {
        props.setTopic(topic);
        localStorage.setItem('Theme', topic);
    };

    const openMenu = () => {
        setOpen(!open);
    };

    const openMenuRight = () => {
        setOpenRight(!openRight);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setOpen(false);
            }
            if (menuRightRef.current && !menuRightRef.current.contains(event.target)) {
                setOpenRight(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [menuRef, menuRightRef]);

    return (
        <div className='headerPosition'>
            <div className='headerAuth prFlexRow'>
                <div className='headerLogo'>
                    <div className='headerLogoLogo'  onClick={() => navigate('/')}>
                        <Icon type='logo'></Icon>
                    </div>

                    <div className='headerLogoText'  onClick={() => navigate('/')}>
                        Play Room
                    </div>
                    <div className='headerTheme'>
                        <div onClick={() => setTopicClick('black')} className={props.topic === 'white' ? 'selected' : 'selected active'}>
                            <Icon type='darkTheme'></Icon>
                        </div>
                        <div onClick={() => setTopicClick('white')} className={props.topic === 'black' ? 'selected' : 'selected active'}>
                            <Icon type='lightTheme'></Icon>
                        </div>
                    </div>
                </div>
                <div className='prFlexRow' style={{ gap: '30px' }}>
                    <div className='userAttempt prFlexRow'>
                        <div className='addUserAttempt'> <Icon type='plus'></Icon></div>
                        <div>
                            <div className='prFlexRow'>
                                <Icon type='playController'></Icon>
                                <div className='userAttemptStatus'>
                                    {t('waiting_for_players')}
                                </div>
                            </div>
                            <div className='userAttemptTime'>
                                05:22
                            </div>
                        </div>
                        <div className='numberUserAttempt'>4</div>
                    </div>

                    <div className='prFlexRow headerMoney'>
                        <div className='headerMoneyWallet'><Icon type='wallet'></Icon></div>
                        <div>
                            <div className='prFlexRow'>
                                <Icon type='fTocken'></Icon>
                                <div>
                                    <span className='prTockenEvailable'>400 </span>/<span className='prTockenBlocked'> 32</span>
                                </div>
                            </div>
                            <div className='prFlexRow'>
                                <Icon type='cTocken'></Icon>
                                <div>
                                    <span className='prTockenEvailable'>400 </span>/<span className='prTockenBlocked'> 32</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='prFlexRow headerUser sideMenu' onClick={openMenu} ref={menuRef}>
                        <div className='headerUserAvatar'>
                            <Icon type='avatar'></Icon>
                        </div>
                        <div>
                            <div className='nickname'>
                                {user?.nickname}
                            </div>
                            <div className='prFlexRow'>
                                <span className='reputation'>{t('reputation')}:</span> <div className='reputationNumber'>{reputation} {(reputation > 4 && t('points')) || (reputation > 1 && t('pointss')) || (reputation === 1 && t('point')) || (reputation === 0 && t('points'))}</div>
                            </div>
                        </div>

                        {open && <div className='openSideMenu'>
                            <div className='openSideMenuNavigation'>
                                <div>{t('rooms')}</div>
                                <div>{t('wallet')}</div>
                                <div>{t('accaunt_verifcation')}</div>
                                <div>{t('settings')}</div>
                            </div>
                            <div className='openSideMenuExit' onClick={logOutClick}>
                                <Icon type='exit' />  {t('log_out')}
                            </div>
                        </div>}
                    </div>

                    <div className='headerButtons prFlexRow'>
                        <Icon type='support'></Icon>
                        <Icon type='notification'></Icon>
                        <div className='sideMenu' ref={menuRightRef}>
                            <div onClick={openMenuRight}>
                                <Icon type='more'></Icon>
                            </div>
                            {openRight && <div className='openSideMenu openSideMenuRight'>
                                <div className='openSideMenuNavigation'>
                                    <div  onClick={() => {navigate('/users');openMenuRight();}}>{t('users_all')}</div>
                                    <div>{t('Dictionary')}</div>
                                    <div  onClick={() => {navigate('/directory');openMenuRight();}}>{t('directory')}</div>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HeaderAuth;
