import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Icon } from "../../components/Icon";
import Pagination from "../../components/Pagination/Pagination";




const ProfilePage = () => {
    const { t } = useTranslation();
    const user = useSelector((state) => state.currentUser.user);
    let reputation =10;

    return (<>
        <div className="profileMainBlock prFlexRow">
            <div className="photoUploadCover"><Icon type='photoUpload'></Icon>
            </div>
            <div className="profileMainBlockInfo prFlexRow">  
                <div className="headerUserAvatarBlock">
                    <div className='headerUserAvatar'>
                        <Icon type='avatar'></Icon>
                    </div>
                    <div className="headerUserAvatarUpload">
                        <Icon type='photoUpload'/>
                    </div>
                </div>
                <div className="profileMainBlockInfoRight">
                    <div className="prFlexRow profileMainBlockInfoRightText">
                        <div className='prFlexRow'>
                            <div className='reputationNumber'>{reputation}</div>
                        </div>
                        <div className="profileFio">{t('last_name')} {t('first_name')}</div>
                        <div style={{paddingTop:'3px'}} className='profileFioEditName'><Icon type='editName'></Icon></div>
                    </div>
                    <div className="profileMainBlockInfoRightNick">
                        @{user?.nickname}
                    </div>
                    <div className="profileStatus">
                        <div className='prFlexRow'>
                            <div className='reputationNumber prFlexRow'> <Icon type='passport'></Icon> <div>{t('status_verificated')}</div> </div>
                        </div>
                    </div>
                    <div className="prFlexRow statistic">
                        <div className="prFlexRow white"><Icon type='playController'/><div className="statisticValue">&nbsp;: 42</div></div>
                        <div className="prFlexRow green"><Icon type='win'/><div className="statisticValue">&nbsp;: 30</div></div>
                        <div className="prFlexRow red"><Icon type='loose'/><div className="statisticValue">&nbsp;: 10</div></div>
                        <div className="prFlexRow orange"><Icon type='draft'/><div className="statisticValue">&nbsp;: 2</div></div>
                    </div>
                </div>

            </div>
            <div className="profileTabs prFlexRow">
                <div className="profileTabsItems active ">{t('my_games')}</div>
                <div className="profileTabsItems">{t('rooms')}</div>
                <div className="profileTabsItems">{t('wallet')}</div>
                <div className="profileTabsItems">{t('settings')}</div>
            </div>
        </div>

        <div className="profileSection">
            <h2>{t('my_games')}
                <div className="infoHelp"><Icon type='info'/></div>
            </h2>
            <div className="gameList">
                <div className="gameItem">
                    <div className="gameItemEdit"><Icon type='edit'/></div>
                    <div className="gameItemBlockInfo">
                        <div className=" prFlexRow profileMainBlockInfoRightText">
                            <div className="profileFio">{t('game_name')}</div>
                            <div style={{paddingTop:'3px'}}><Icon type='pc'></Icon></div>
                        </div>
                        <div className="profileMainBlockInfoRightNick">
                            {t('game_nick')}: <span style={{fontWeight:'700'}}>{user?.nickname}</span>
                        </div>
                        <div className="prFlexRow statistic">
                            <div className="prFlexRow white"><Icon type='playController'/><div className="statisticValue">&nbsp;: 42</div></div>
                            <div className="prFlexRow green"><Icon type='win'/><div className="statisticValue">&nbsp;: 30</div></div>
                            <div className="prFlexRow red"><Icon type='loose'/><div className="statisticValue">&nbsp;: 10</div></div>
                            <div className="prFlexRow orange"><Icon type='draft'/><div className="statisticValue">&nbsp;: 2</div></div>
                        </div>
                </div>


                </div>

            </div>
            <Pagination
                className="pagination-bar"
                currentPage={1}
                totalCount={30}
                pageSize={6}
                onPageChange={async (page) => {
                    console.log(page);
                }}
            />

        </div>

        <div className="profileSection" style={{marginBottom:'0px'}}>
            <div className="prFlexRow profileSectionHeader">
                <h2 >{t('popular_games')}</h2>
                <a className="profileSectionHeaderLink link">{t('all_games')}<Icon type='rightarrow'/></a>
            </div>
           
            <div className="gameList">
                <div className="gameItem gameItemPopular">
                    <div className="gameItemEdit"><Icon type='bigplus'/></div>
                    <div className="gameItemBlockInfo">
                        <div className=" prFlexRow profileMainBlockInfoRightText">
                            <div className="profileFio">{t('game_name')}</div>
                            <div style={{paddingTop:'3px'}}><Icon type='pc'></Icon></div>
                            <div style={{paddingTop:'3px'}}><Icon type='xbox'></Icon></div>
                            <div style={{paddingTop:'3px'}}><Icon type='ps'></Icon></div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
        </>
    );
};

export default ProfilePage;